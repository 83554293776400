import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";


@Injectable({
	providedIn: 'root'
})

export class JwtInterceptorService implements HttpInterceptor{

	constructor() { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		const token = localStorage.getItem('token');
		if (token) {
			request = request.clone({
				setHeaders: {
					'Token': `${token}`,
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			});
	
			
		}

		return next.handle(request);

	}
}
