import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgForm, FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { RouterModule ,PreloadAllModules} from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LocalStorage } from './extras/localstorage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpHandler } from '@angular/common/http';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectizeModule } from 'ng-selectize';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzStepsModule } from 'ng-zorro-antd';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzButtonModule } from 'ng-zorro-antd';
import { SelectivePreloadingStrategy } from './selective-preloading-strategy';
import { NzSelectModule } from 'ng-zorro-antd';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzCheckboxModule } from 'ng-zorro-antd';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzIconModule } from 'ng-zorro-antd';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTableModule } from 'ng-zorro-antd';
import { NzFormModule } from 'ng-zorro-antd';

//Layout Modules
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { Page403Component } from './extras/403/403.component';
import { Page500Component } from './extras/500/500.component';
//Directives
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Sidebar_Directives } from './shared/directives/side-nav.directive';
import {cardPortletRefresh,cardPortletDelete} from './shared/directives/cards.directive';
// Routing Module
import { AppRoutes } from './app.routing';

// App Component
import { AppComponent } from './app.component';

//intercepetor
import { HttperrorsService } from './httperrors.interceptor';
import { JwtInterceptorService } from "./jwt-interceptor.interceptor";
import { InvalidpageComponent } from './extras/invalidpage/invalidpage.component';
import { ShopifyComponent } from './shopify/shopify.component';
import { ShopifyDashboardComponent } from './shopify-dashboard/shopify-dashboard.component';
import { PrivacyPolicyComponent } from './new-dashboard/privacy-policy/privacy-policy.component';
import { StaticShopifyComponent } from './new-dashboard/static-shopify/static-shopify.component';




export function HttpLoaderFactory(httpClient: HttpClient) {
　　　　return new TranslateHttpLoader(httpClient);
　　}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, { useHash: true ,
            // enableTracing:true,
            preloadingStrategy:SelectivePreloadingStrategy,
            // onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled'
        }),
        FormsModule,
        HttpClientModule,
        BsDatepickerModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
            }
        }),
        NgSelectizeModule,
        NgbModule.forRoot(),
        NzModalModule,
        NzTreeSelectModule,
        NzStepsModule,
        NzButtonModule,
        NzSelectModule,
        NzTreeModule,
        NzCheckboxModule,
        NzRadioModule,
        NzIconModule,
        NzTabsModule,
        NzTableModule,
        NzFormModule,
        ReactiveFormsModule 

    ],
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        AuthenticationLayoutComponent,
        Sidebar_Directives,
        Page403Component,
        Page500Component,
        cardPortletRefresh,
        cardPortletDelete,
        InvalidpageComponent,
        ShopifyComponent,
        ShopifyDashboardComponent,
        PrivacyPolicyComponent,
        StaticShopifyComponent
        
    ],
    providers: [
        LocalStorage,
        SelectivePreloadingStrategy,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttperrorsService, multi: true}],
    bootstrap: [AppComponent]
})


export class AppModule { }
