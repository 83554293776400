import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shopify-dashboard',
  templateUrl: './shopify-dashboard.component.html',
  styleUrls: ['./shopify-dashboard.component.css']
})
export class ShopifyDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
