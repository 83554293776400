import {HttpClient,HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import {HttpParams} from "@angular/common/http";


export function serializeData(data){
	var buffer = [];
        
        // Serialize each key in the object.
        for ( var name in data ) {
            if ( ! data.hasOwnProperty( name ) ) {
                continue;
            }
            
            var value = data[ name ];     
            if (typeof value=='object'){
                value = JSON.stringify(value)
            }
            buffer.push(
                encodeURIComponent( name ) +
                "=" +
                encodeURIComponent( ( value == null ) ? "" : value )
            );
            
            
        }

        // Serialize the buffer and clean it up for transportation.
        var source = buffer.join( "&" ).replace( /%20/g, "+" );

        return( source );
}


 

export function Params(dataTablesParameters){ 
    var params = new HttpParams().set('draw',dataTablesParameters.draw)
    .set('columns',JSON.stringify(dataTablesParameters.columns))
    .set('length',dataTablesParameters.length)
    .set('order',JSON.stringify(dataTablesParameters.order))
    .set('start',dataTablesParameters.start)
    .set('search',JSON.stringify(dataTablesParameters.search))

    return params
}