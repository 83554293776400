import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {HttpClient,HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import {Router,ActivatedRoute} from '@angular/router';
import {HttpParams} from "@angular/common/http";
import {serializeData} from '../postformdata';

@Component({
    selector: 'app-dashboard',
    templateUrl: './common-layout.component.html',
    styleUrls: ['./common.component.css']
})

export class CommonLayoutComponent implements OnInit {
    
    public app : any;
    public headerThemes: any;
    public changeHeader: any;
    public sidenavThemes: any;
    public changeSidenav: any;
    public headerSelected: any;
    public sidenavSelected : any;
    public searchActived : any;
    public searchModel: any;
    username = localStorage.getItem('username')
    useremail = localStorage.getItem('useremail')
    usertype = localStorage.getItem('usertype')
    ip = localStorage.getItem('ip')

    language


    //设置多语言
    constructor(
        public translate: TranslateService,
        private http:HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private el:ElementRef,
    ) 
    {
        translate.addLangs(["zh", "en","fr"]);
        translate.setDefaultLang('zh');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/(zh|en|fr)/) ? browserLang : 'zh');
        this.language = browserLang.match(/(zh|en|fr)/) ? browserLang : 'zh'
     
        this.app = {
            layout: {
                sidePanelOpen: false,
                isMenuOpened: false,
                isMenuCollapsed: true,
                themeConfigOpen: false,
                rtlActived: false,
                searchActived: false
            }
        };  

        //set themes
        this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
        this.changeHeader = changeHeader;
    
        function changeHeader(headerTheme) {
            this.headerSelected = headerTheme;
        }
    
        this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
        this.changeSidenav = changeSidenav;
    
        function changeSidenav(sidenavTheme) {
            this.sidenavSelected = sidenavTheme;
        }
    }

    
    public menu = []


    current = 0;

    index = [];
    currentagent;
    currentstore;checkplat=[];
    checkstore = [];
    checkall = false;

    
    value: string[] = [];
    nodes = []
    selectedAgent=''
    agent = []
    agentChange(value: string){
        this.nodes = this.setnodes()
    }
    
    changelanguage(){
        this.translate.use(this.language)
    }

    thisyear
    ngOnInit(){
        this.http.get('assets/datas/allapi.json').subscribe(data =>{

            for(let api of data[Symbol.iterator]()){
              this.menu.push(api['key'])
              try{
                for(let sonapi of api['children']){
                  this.menu.push(sonapi['key'])
                }
              }
              catch{}
            }
        })
        this.thisyear = new Date().getFullYear()
        var params = new HttpParams().set('email',this.useremail)
        this.http.get(this.ip+"/user/getpermissionbyemail/",{params:params}).subscribe(res=>{
            var permission = res['data']
            for(let i of this.menu){
                try{
                    if(permission.indexOf(i)==-1){
                        this.el.nativeElement.querySelector('#'+i).style.display='none'
                    }
                    else{
                        this.el.nativeElement.querySelector('#'+i).style.display='block'
                    }
                }catch{
                    continue
                }
            }

        })
        this.cancel()


    }

    checkallstore(){
        if (this.checkall ==true){
            this.value = []
            for(let n of this.nodes){
                for(let m of n.children){
                    this.value.push(m.key)
                }
            }
        }else{
            this.value = []
        }
    }

    setnodes(){
        this.checkall = true
        this.value = []
        var nodes = []
        var permission =JSON.parse(localStorage.getItem('permission'))[this.selectedAgent]
        var store = JSON.parse(localStorage.getItem('store'))
        for(let brand in permission){
            var brandname = []
            for (let market of permission[brand]){
                if (typeof market =='object'){
                    for(let i in market){
                        if(['ele','jddj'].includes(i)){
                            var shopname = []
                            for(let s of market[i]){
                                if(store.includes(brand +' ' + i +' '+s)){
                                    this.value.push(brand +' ' + i +' '+s)
                                }else{
                                    this.checkall = false
                                }
                                shopname.push({
                                   'title':s.toUpperCase(),
                                   'key':brand +' ' + i +' '+s,
                                   'icon':"",
                                   "isLeaf":true
                                })
                                
                                
                            }
                            brandname.push({
                                'title':i,
                                'key':brand +' '+i,
                                'icon':"assets/images/logo/"+i+".jpg",
                                "children":shopname
                            })
                        }
                        else{
                            if(typeof market[i] =='object'){
                                var shopname = []
                                for(let s of market[i]['supplier']){
                                    if(store.includes(brand +' ' + i +' supplier '+s)){
                                        this.value.push(brand +' ' + i +' supplier '+s)
                                    }else{
                                        this.checkall = false
                                    }
                                    shopname.push({
                                       'title':s.toUpperCase(),
                                       'key':brand +' ' + i +' supplier '+s,
                                       'icon':"",
                                       "isLeaf":true
                                    })

                                }
                                
                                brandname.push({
                                    'title':i,
                                    'key':brand +' '+i,
                                    'icon':"assets/images/logo/"+i+".jpg",
                                    "children":shopname
                                })
                            }
                        }
                    }
                    
                    
                    
                }else{
                    if(store.includes(brand+' '+market)){
                        this.value.push(brand +' '+ market)
                    }else{
                            this.checkall = false
                        }
                    brandname.push({
                        'title':market.toUpperCase(),
                        'key':brand +' '+ market,
                        'icon':"assets/images/logo/"+market+".jpg",
                        "isLeaf":true
                    })
                    
                }
                
            }
            nodes.push({
                'title':brand.toUpperCase(),
                'key': brand,
                'icon':"assets/images/logo/"+brand+".jpg",
                'children':brandname
            })

        }
        return nodes
    }

    //登出
    logout = function() {
        this.http.post(this.ip+"/user/logout/",{}).subscribe(data =>{
            localStorage.removeItem('username')
            localStorage.removeItem('useremail') 
            localStorage.removeItem('token')
            localStorage.removeItem('store') 
            localStorage.removeItem('permission') 
            localStorage.removeItem('agent') 
            localStorage.removeItem('usertype') 

            this.router.navigate(['/authentication/sign-in'])
        })

    };


    cancel(){
        this.agent = []
        var permission = JSON.parse(localStorage.getItem('permission'))
        for(let a in permission){
            this.agent.push(a)
        }
        this.selectedAgent = localStorage.getItem('agent');
        this.nodes = this.setnodes()
    }
    submit(){
        var checkstores = []
        
        for(let n of this.nodes){
            for(let m of n.children){
                
                if (m.children){
                    for(let s of m.children){
                        if (s.checked==true){
                            checkstores.push(s.key)
                        }
                    }
                }
                
                else{
                    if (m.checked==true){
                        checkstores.push(m.key)
                    }
                }
                    
                
            }
        }
        if(checkstores.length!=0){
            var data = serializeData({'agent':this.selectedAgent,'store':checkstores.join(';')})
            new Promise((resolve,reject)=>{
                this.http.post(this.ip+'/user/storevisitrecord/',data).subscribe(res=>{
                    console.log(res['msg'])
                    resolve()
                })
            }).then(res=>{
                localStorage.setItem('agent',this.selectedAgent)
                localStorage.setItem('store',JSON.stringify(checkstores))
                location.reload()
            })
            
        }
        

    }

}