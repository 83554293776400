import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { Page403Component } from './extras/403/403.component';
import { Page500Component } from './extras/500/500.component';
import { InvalidpageComponent } from './extras/invalidpage/invalidpage.component';
import { ShopifyComponent } from './shopify/shopify.component';
import { ShopifyDashboardComponent } from './shopify-dashboard/shopify-dashboard.component';
import { PrivacyPolicyComponent } from './new-dashboard/privacy-policy/privacy-policy.component';
import { StaticShopifyComponent } from './new-dashboard/static-shopify/static-shopify.component';

export const AppRoutes: Routes = [
    
    {
        path: '',
        redirectTo: '/authentication/sign-in',
        pathMatch: 'full'
    },
    
    {
        path: '',
        component: CommonLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                data: { preload: true}
            },
            {
                path: 'orders',
                loadChildren: './orders/orders.module#OrdersModule'
            },
            {
                path: 'reports',
                loadChildren: './reports/reports.module#ReportsModule'
            },
            {
                path: 'oprations',
                loadChildren: './oprations/oprations.module#OprationsModule'
            },
            {
                path: 'monitors',
                loadChildren: './monitors/monitors.module#MonitorsModule'
            },
            {
                path: 'details',
                loadChildren: './details/details.module#DetailsModule'
            },
            {
                path: 'users',
                loadChildren: './users/user.module#UserModule'
            },
            {
                path: '403',
                component: Page403Component,
            },
            {
                path: '500',
                component: Page500Component,
            }
        ]
    },
    
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: './extras/authentication.modules#AuthenticationModule'
            }
        ]
    },
    {
        path: 'invalidpage',
        component: InvalidpageComponent
    },
    {
        path:'ecommerce-privacy-policy',
        component:PrivacyPolicyComponent
    },
    {
        path:'ecommerce-example',
        component:StaticShopifyComponent
    },

    {
        path: 'ecommerce/:platform/:token',
        component: ShopifyComponent
    },
    {
        path: 'ecommerce-dashboard',
        component: ShopifyDashboardComponent,
        children: [
            {
                path: 'dashboard/:url',
                loadChildren: './new-dashboard/new-dashboard.module#NewDashboardModule',
               
            }
        ]
    }


];

