import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {HttpClient,HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
	constructor(public translate: TranslateService,private http:HttpClient,private metaService: Meta) {
		
		translate.addLangs(["zh", "en","fr"]);
    	translate.setDefaultLang('zh');

	    const browserLang = translate.getBrowserLang();
	    translate.use(browserLang.match(/(zh|en|fr)/) ? browserLang : 'zh');
	}
	ngOnInit(){
		this.http.get('../assets/datas/ip.json').subscribe(data =>{
            document.title=data['title']
            localStorage.setItem('ip',data['adress']);
        })
		
		
    }
  
  	
}
