import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd';
import {HttpClient,HttpErrorResponse,HttpHeaders,HttpParams} from '@angular/common/http';
import {serializeData,Params} from '../postformdata';


@Component({
	selector: 'app-shopify',
	templateUrl: './shopify.component.html',
	styleUrls: ['./shopify.component.css']
	})
export class ShopifyComponent implements OnInit {

	constructor(private route: ActivatedRoute,
				private metaService: Meta,
				private fb: FormBuilder,
				private modalService: NzModalService,
				private http:HttpClient) { }
	ip = localStorage.getItem('ip')
	token = this.route.snapshot.paramMap.get('token')	
	platform = this.route.snapshot.paramMap.get('platform')

	validateForm: FormGroup;
	submitForm(): void {
		for (const i in this.validateForm.controls) {
			this.validateForm.controls[i].markAsDirty();
			this.validateForm.controls[i].updateValueAndValidity();
		}
		var data={}
		data['json_data']={}
		if(this.validateForm.get('tmall_appkey').value && this.validateForm.get('tmall_secret').value && this.validateForm.get('tmall_token').value){
			data['json_data']['tmall'] = {app_key:this.validateForm.get('tmall_appkey').value,
							shop:this.token,
							secret:this.validateForm.get('tmall_secret').value,
							access_token:this.validateForm.get('tmall_token').value,
							platform :this.platform
			}
			
		} 
		if(this.validateForm.get('jd_appkey').value && this.validateForm.get('jd_secret').value && this.validateForm.get('jd_token').value){
			data['json_data']['jd'] = {app_key:this.validateForm.get('jd_appkey').value,
							shop:this.token,
							secret:this.validateForm.get('jd_secret').value,
							access_token:this.validateForm.get('jd_token').value,
							platform :this.platform
			}
			
		} 
		if(this.validateForm.get('kaola_appkey').value && this.validateForm.get('kaola_secret').value && this.validateForm.get('kaola_token').value){
			data['json_data']['kaola'] = {app_key:this.validateForm.get('kaola_appkey').value,
							shop:this.token,
							secret:this.validateForm.get('kaola_secret').value,
							access_token:this.validateForm.get('kaola_token').value,
							platform :this.platform
			}
			
		}
		if(this.validateForm.get('lrb_appkey').value && this.validateForm.get('lrb_secret').value && this.validateForm.get('lrb_token').value){
			data['json_data']['lrb'] = {app_key:this.validateForm.get('lrb_appkey').value,
							shop:this.token,
							secret:this.validateForm.get('lrb_secret').value,
							access_token:this.validateForm.get('lrb_token').value,
							platform :this.platform
			}
			
		}
		if(JSON.stringify(data)!='{"json_data":{}}'){
			var postdata = serializeData(data)
			this.http.post(this.ip+'/shopify/createaccount/',postdata).subscribe(res=>{
				this.modalService.info({
			      nzContent: res['msg']
			    });
			    if(res['msg']=='success'){
			    	this.current += 1;
			    }
			})
		}else{
			this.modalService.warning({
		      nzTitle: 'Submit failed',
		      nzContent: 'It cannot be all empty. At least one platform is complete'
		    });
		}
		
	}


	ngOnInit(): void {
		this.validateForm = this.fb.group({
			tmall_appkey: [],
			tmall_secret: [],
			tmall_token: [],
			jd_appkey:[],
			jd_secret:[],
			jd_token:[],
			kaola_appkey:[],
			kaola_secret:[],
			kaola_token:[],
			lrb_appkey:[],
			lrb_secret:[],
			lrb_token:[],
		});
	}

	current = 0;

	pre(): void {
		this.current -= 1;
	}

	next(): void {
		if (this.current==1){
			this.submitForm()
		}else{
			this.current += 1;
		}
		
	}
}
