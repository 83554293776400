import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private router:Router,private location: Location){

	}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
			return this.checkLogin();
		}

	checkLogin(): boolean {

        // 判断本地有没有token
        const token = localStorage.getItem('token');

        // 如果token有值，继续跳转，否则跳转到首页
        if (token) { return true; }
        
        this.router.navigate(['/authentication/sign-in']);
        
        return false;
    }
}
