import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpHandler, HttpInterceptor, HttpEvent} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class HttperrorsService implements HttpInterceptor {

	constructor(private router:Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 403) {
                this.router.navigate(['/authentication/sign-in'])
            }
            
            const error = err.error || err.statusText;
   
            return throwError(error);
        }))

    }
}
